// Responsive Image Cover
.sn-media-slider .media-wrapper{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.sn-media-slider .slides .slide .media-wrapper img{
  display: none !important;
}


// Remove default unwanted styling
.mediaSlider{
  > h2, > p{
    &:empty{display: none;}
  }
}
.sn-media-slider .no-paging.top-left-paging,
.sn-media-slider .no-paging.top-center-paging,
.sn-media-slider .no-paging.top-right-paging{
  margin-top: 0;
}
.sn-media-slider .no-paging.bottom-left-paging,
.sn-media-slider .no-paging.bottom-center-paging,
.sn-media-slider .no-paging.bottom-right-paging{
  margin-bottom: 0;
}



// All media sliders
.sn-media-slider{
  .slider-pagination{
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .paging-item{
      height: 14px;
      width: 14px;
      background-color: $blue;
      margin: 5px 10px;
      transition: background-color .2s ease;
      &:hover,
      &.flex-active{
        background-color: $red;
      }
    }
  }
}


// .custom-media-slider{
//   .sn-media-slider{
//     .slider{
//       @media screen and (max-width: 1720px){
//         max-height: 430px;
//         min-height: 430px;
//       }
//       @media screen and (max-width: 299px){
//         max-height: 350px;
//         min-height: 350px;
//       }
//     }
//     .slide{
//       > a{
//         height: 100%;
//         display: flex;
//         flex-flow: column;
//         justify-content: flex-end;
//         align-items: center;
//         text-decoration: none;
//         &:hover{text-decoration: none; }
//       }
//       .media-wrapper{
//         z-index: -1;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//       }
//     }
//     .slides .slide-overlay{
//       position: relative;
//       height: auto;
//       max-width: $maxWidth;
//       width: 100%;
//       margin: 0 auto;
//       padding: 0 5%;
//     }
//
//     .slide-text{
//       background-color: #FFF;
//       background-image: linear-gradient(to top, #FFF 0, rgba(#FFF, 0) 75%), url(../images/dot-pattern.svg);
//       background-size: 100%, 5px 5px;
//       background-repeat: no-repeat, repeat;
//       padding: 30px 30px 0 30px;
//       &:hover{
//         text-decoration: none;
//       }
//     }
//     .slide-title{
//       color: $red;
//       font-size: em(48);
//       font-weight: 800;
//       line-height: 1.1;
//       text-transform: uppercase;
//       text-shadow: none;
//       margin: 0 0 20px 0;
//     }
//     .slide-description{
//       margin: 0 0 30px 0;
//       position: relative;
//       background-color: $link-color;
//       display: inline-flex;
//       flex-flow: row;
//       align-items: stretch;
//       color: #fff;
//       font-weight: 500;
//       font-size: em(16);
//       text-transform: uppercase;
//       padding: 8px 0px 8px 15px;
//       line-height: 1.3;
//       text-shadow: none;
//       &:hover{
//         text-decoration: none;
//         &:after{
//           padding: 8px 8px 8px 16px;
//         }
//       }
//       &:after{
//         background-color: $accent-color;
//         color: $red;
//         content: "\f101"; // Right Arrow
//         font-family: FontAwesome;
//         line-height: .8em;
//         padding: 8px 12px;
//         display:inline-flex;
//         flex-flow: row;
//         align-items: center;
//         margin: -8px 0 -8px 15px;
//         transition: color .2s, padding .2s;
//       }
//     }
//   }
// }


.custom-ratio{
  .sn-media-slider{
    .slider{
      .page-manager-visible &{height: calc(25.50vw - 60px) !important;}
      height: 25.50vw !important;
      .slide-overlay{
        padding: 10px;
      }
      .slide-description,
      .slide-title{
        margin: 5px auto;
        max-width: $maxWidth - 60px;
      }
    }
  }
}
