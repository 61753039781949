// Site Footer
$footer-padding-v: 15px;
$heading-alignment-mod: 9px;

.snFooterContainer{
  background-color: $blue;
  padding-top: 42px;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #D0242D;
    position: absolute;
    top: 40px;
    left: 0;
  }
}
#siteFooter {
  width:100%;
  max-width:$maxWidth;
  margin:0 auto;
  font-family: $fontOne;

  // display: block;
  margin: auto;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: em(16);
  clear:both;
  color: $gray-md;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: $footer-padding-v;
  min-height: 200px;
  .pageElement{
    margin: 0;
  }

  > ul {
    text-align: center;
    padding: 0;
    width: 100%;
    // border-top: 1px solid darken($blue, 10%);
    margin-top: 10px;
    color: #727375;
    font-size: 14px;
    li {
      display: inline-block;
      padding: 0 .2em;
      margin: 6px 0 0 0;
      a {
        display: inline-block;
        color: #727375;
      }
      &:hover { display: inline-block; }
    }
  }
}
#siteFooter li#poweredByNGIN{
	a{
		display:inline-block;
		width:120px;
		height:20px;
		position:relative;
		&:after{
			content:url($asset-path-for+'/logo_images/logo.svg'); // /logo_images/logo.svg") right center no-repeat
			display:block;
			position:absolute;
			top:8px;
			left:0;
			width:100%;
		}
		img{
			display:none;
		}
	}
}

// Ajaxed content
#global-footer{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0 30px 0;
  @include font-smoothing;
  .footer-column{
    padding: 0 $footer-padding-v;
    flex-basis: 33.33333333%;
  }
  @media screen and (min-width: 561px) and (max-width: 900px){
    .footer-column{
      flex-basis: 50%;
    }
    #fc2{
      flex-basis: 100%;
      order: -1;
    }
  }
  @media screen and (max-width: 560px){
    .footer-column{
      flex-basis: 100%;
    }
  }
  // Resets and Normalization
  .text,
  .sn-social-media-list,
  .footer-nav-links-content{
    padding-left: $heading-alignment-mod;
  }
  a{
    color: $gray-md;
    font-weight: 500;
    &:hover{
      color: $red;
      text-decoration: none;
    }
  }
  .pageEl{ margin-bottom: 30px; }
  .footer-connect,
  .footer-nav-links{ margin-bottom: 0px; }

  // Navigation and Quick Links
  .footer-nav-links-content,
  .footer-quick-links .text{
    text-transform: uppercase;
  }
  .footer-nav-links,
  .footer-connect{
    .textBlockElement > h3{
      margin-bottom: .5em;
    }
  }
  .footer-nav-links-content{
    list-style: none;
  }
}

// Social Media Header and Footer
#global-footer,
#global-header{
  .global-social-links{
    .sn-social-media-list{
      display: flex;
      flex-flow: row wrap;
    }
    .sn-social-media-icon {
      color: $blue;
      background: $red;
      transition: color .2s ease, background .2s ease;
      height: 30px;
      line-height: 30px;
      width: 30px;
      &:before {
        font-size: 20px;
        line-height: 30px;
        width: auto;
      }
      &:hover{
        background: $gray-md;
        color: $blue;
      }
    }
  }
}
