#sn-site-logo {
  background-color: #fff;
  z-index: 1000;
  display: inline-block;
  width: auto;
  margin: 0 auto 12px auto;
  position: relative;
  &:after{
    content:"";
    display:block;
    position: absolute;
    width: 390px;
    height: 0px;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 86px solid #FFF;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  .sn-site-logo-wrap {
    display: block;
    table-layout: fixed;
    padding: 3px;
  }
  .sn-site-logo-background {
    // display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    margin: 0 auto;
    width: 314px;
    height: 80px;
  }
  .has-mobile-nav &{
    margin: 7px auto 7px 7px;
    &:after{
      width: 212px;
      border-left: 0px solid transparent;
      border-top: 56px solid #FFF;
      left: 0;
      transform: none;
    }
    .sn-site-logo-background{
      width: 197px;
      height: 50px;
    }
  }
}

#global-header{
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(230px, -50%);
  width: auto;
  .pageElement{
    margin: 0;
  }
  .has-mobile-nav &{
    transform: translate(150px, -50%);
    min-width: 151px;
  }
  @media screen and (max-width: 470px){display: none;}
}
