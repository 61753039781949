#home.user_mode{
  #siteContainer{ padding-top: 0; }
}

.user_mode {
  .theme-nav-forward.page_node_2149986,
  .theme-nav-item.home{
    display: none !important;
  }
}
